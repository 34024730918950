import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Container from '../components/Container'
import { PageHeader } from '../types/definitions'
import ServiceList from '../components/ServiceList'

type ServicePageProps = {
  data: {
    page: {
      frontmatter: {
        header: PageHeader
        footer: {
          showCTA: boolean
        }
        seo: { title: string; description: string }
      }
    }
    services: {
      nodes: { frontmatter: { title: string; description: string } }[]
    }
  }
}

const ServicePage: FunctionComponent<ServicePageProps> = ({ data }) => {
  const { header, seo, footer } = data.page.frontmatter

  const services = data.services.nodes.map((node) => {
    return {
      title: node.frontmatter.title,
      description: node.frontmatter.description,
    }
  })

  return (
    <Layout header={header} footer={footer} seo={seo}>
      <Container bg="blue.0">
        <ServiceList services={services} />
      </Container>
    </Layout>
  )
}

export default ServicePage

export const pageQuery = graphql`
  query Services {
    page: mdx(frontmatter: { templateKey: { eq: "service-page" } }) {
      frontmatter {
        footer {
          showCTA
        }
        ...header
        seo {
          description
          title
        }
      }
    }
    services: allMdx(
      filter: { frontmatter: { templateKey: { eq: "services-page" } } }
    ) {
      nodes {
        frontmatter {
          title
          description
        }
      }
    }
  }
`
